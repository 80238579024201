<template>
<b-row>
    <b-col cols="12" xl="4" class="nopadding">
      <b-card>
        <div slot="header">
          <strong># {{$t('common.new_type')}}</strong>
        </div>
        <div class="text-center">
          <img v-if="type.logo" :src="computed_logo(type.logo)"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <img v-else src="http://ssl.gstatic.com/accounts/ui/avatar_2x.png"
          class="avatar img-circle img-thumbnail" alt="avatar">
          <h6>{{$t('common.choose_another_image')}}</h6>
          <input type="file" class="text-center center-block file-upload"
          @change="getImage" id="fileControl">
        </div>
        <br>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_name">{{$t('common.type')}}</label>
              <div v-if="$v.type.name.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.type.name.required">{{$t('common.name_required')}}</div>
                <div class="text-danger" v-else-if="!$v.type.name.isUnique">{{$t('common.name_existing')}}</div>
              </div>
              <b-form-input class="form-control" type="text" :placeholder="$t('common.placeholder_name')"
              id="txt_name" v-model="type.name" maxlength="50" ref="txt_name" size="sm"
              v-on:input="$v.type.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="input_code">{{$t('common.code')}}</label>
              <div v-if="$v.type.code.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.type.code.required">{{$t('common.code_required')}}</div>
              </div>
              <b-form-input class="form-control" type="text" :placeholder="$t('common.placeholder_code')"
              id="input_code" v-model="type.code" maxlength="25" :disabled="type.id!=null" size="sm"
              v-on:input="$v.type.code.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_des">{{$t('common.description')}}</label>
              <b-form-textarea type="text" id="txt_des"
              v-model="type.des" maxlength="200"
              :placeholder="$t('common.placeholder_description')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-success" @click.prevent="clear()"><span class="icon is-small">
            <i class="fa fa-refresh"></i> {{$t('common.button.refresh')}}</span></b-button>
          <b-button class="ml-2" variant="outline-primary" @click.prevent="saveType()"><span class="icon is-small">
            <i class="fa fa-check"></i> {{$t('common.button.save')}} </span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="8" class="nopadding">
      <transition name="slide">
      <b-card>
        <div slot="header"><strong><i class="fa fa-list" aria-hidden="true"></i> {{$t('common.type_list')}}</strong></div>
        <b-form-group label-for="type-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button"><i class="fa fa-search" aria-hidden="true"></i> {{$t('common.search')}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
          <template slot="company" slot-scope="data">
            <label v-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else></label>
          </template>
           <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else>{{data.item.company.name}}</label>
          </template>
          <template slot="rent_type" slot-scope="data">
            <label v-if="data.item.rent_type">{{data.item.rent_type.name}}</label>
            <label v-else></label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
           <template slot="rent_type" slot-scope="data">
            <label v-if="data.item.rent_type">{{formatPrice(data.item.rent_type.name)}}</label>
            <label v-else></label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm" v-if="data.item.type!=1000">
              <b-button variant="outline-danger"
              v-if="data.item.status>-1"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t('common.button.delete')}}
              </b-button>
              <b-button variant="outline-success" v-else
                @click="recoverObj(data.item)">
                <i class="fa fa-recycle"></i> {{$t('common.button.recover')}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
    <b-modal id="viewModal" title="Thông báo" header-bg-variant="info" no-body ok-only>
      <div class="h3">{{$t('common.image_error')}}</div>
    </b-modal>
</b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength,maxLength,numeric,between,requiredIf } from 'vuelidate/lib/validators'
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
import Pager from '../Pager'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { uuid } from 'vue-uuid'
import { Business } from '../../helpers/business'

var axios = require("axios");
  export default {
    mixins: [validationMixin],
    components: {Pager},
    props: {
      hover: {
        type: Boolean,
        default: true
      }
    },
    data: function () {
      return {
        processing:false,
        money: {
          decimal: ',',
          thousands: '.',
          prefix: '$ ',
          precision: 0,
          masked: false
        },
        type:{
          id:null,
          logo:null,
          name:null,
          code:moment().valueOf().toString(),
          des:null,
          type:0,
          company_id:null,
          branch_id:null,
          user_create_id:null,
          user_update_id:null,
          business:0
        }
      }
    },

    validations: {
      type: {
        name:{
          required,
          maxLength:maxLength(50),
          async isUnique(value) {
            if (value==null) return true
            if(this.type && this.type.id) return true

            const res = await this.checkNameUnique({name:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        },
        code: {
          required,
          maxLength:maxLength(25),
          async isUnique(value) {
            if (value==null) return true
            if(this.type && this.type.id) return true

            const res = await this.checkCodeUnique({code:value,company_id:this.user_login.company_id})
            return Boolean(res.data)
          }
        }
      }
    },
    mounted() {
      let vm=this
      vm.$refs.txt_name.$el.focus()
      vm.get_ls_type()
    },

    computed: {
      ...mapGetters('st_type', ['filter','page','per_page','page_count']),
      fields(){
        let fields=[]
        let user=this.$store.state.st_authentication.user
        if(user.role==Role.Super_Admin){
          fields.push({key: 'company',label:this.$t('common.company'),sortable: true, variant:'info'})
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true})
          fields.push({key: 'business',label:this.$t('common.business'),sortable: true})
        }

        if(user.role==Role.Admin){
          fields.push({key: 'branch',label:this.$t('common.branch'),sortable: true, variant:'info'})
        }

        fields.push({key: 'name',label:this.$t('common.type'),sortable: true, variant:'primary'})
        fields.push({key: 'code',label:this.$t('common.code'),sortable: true})
        fields.push({key: 'des',label:this.$t('common.description'),sortable: true})

        if(user.company.business==Business.hotel){
          fields.push({key: 'rent_type',label:this.$t('common.rent_type'),sortable: true})
        }
        fields.push({key: 'created_at',label:this.$t('common.created_at'),sortable: true})
        fields.push({key: 'status',label:this.$t('common.status'),sortable: true})

        if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
          fields.push({key: 'action',label:'',sortable: false})
        }

        return fields
      },
      user_login() {
        return this.$store.state.st_authentication.user
      },
      business() {
        let user= this.$store.state.st_authentication.user
        return user.company.business
      },
      is_admin() {
        let user= this.$store.state.st_authentication.user
        return user && user.role==Role.Admin
      },
      is_branch() {
        let user= this.$store.state.st_authentication.user
        return user && user.role==Role.Branch_Admin
      },
      filter:{
        get: function(){
          return this.$store.state.st_type.filter
        },
        set: function(text){
          this.$store.commit('st_type/set_filter',text)
        }
      },
      filteredAndSortedData: {
        get: function(){
          let vm=this
          debugger
          let result = vm.$store.state.st_type.ls_type
          if (vm.filter) {
            debugger
            result = result.filter(item =>vm.searchLike(item))
          }
          debugger
          return result
        }
      }
    },
    methods: {
      ...mapActions('st_type', ['get_ls_type','apply_filter','change_page']),
      async checkNameUnique(param){
        return await this.$store.dispatch(`st_type/name_unique`,param)
      },
      rowDeleted(item, type){
        debugger
        if (!item || type !== 'row') return
        debugger
        if (item.status === -1) return 'table-danger'
      },
      async checkCodeUnique(param){
        return await this.$store.dispatch(`st_type/code_unique`,param)
      },
      _validations () {
        return new Promise(resolve => {
          if (this.$v.$error || !this.$v.$pending) {
              return resolve()
          }
          let poll = setInterval(() => {
          if (!this.$v.$pending) {
              clearInterval(poll)
              resolve()
          }
          }, 200)
        })
      },
      async isValid () {
        this.$v.$reset()
        this.$v.$touch()
        await this._validations()
        return Promise.resolve(!this.$v.$error)
      },
      async saveType() {
        const isValid = await this.isValid()
        if (!isValid) {
            return
        }

        var app = this
        if (app.processing === true) {
            return
        }

        app.processing = true
        if(app.type.id){
          app.type.user_update_id=app.user_login.id
          app.$store.dispatch('st_type/update',app.type)
          .then(function (resp) {
            app.get_ls_type()
            app.clear()
            app.processing = false
            app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }else{
          app.type.user_create_id=app.user_login.id
          app.type.business=app.user_login.company.business
          app.type.company_id=app.user_login.company_id
          app.type.branch_id=app.user_login.branch_id

          app.$store.dispatch('st_type/create',app.type)
          .then(function (resp) {
              app.get_ls_type()
              app.clear()
              app.processing = false
              app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          })
          .catch(function (resp) {
            app.processing = false
            app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          })
        }
      },

      async recoverObj(obj){
        let app=this
        if(obj.status>=0) return
        let co=await app.$store.dispatch('st_type/recover_obj',obj.id)
        if(co && co.data.ok){
          app.get_ls_type()
          app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      confirmDelete(obj) {
        this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
        .then(value => {
          if(value!=true) return
          this.deleteObj(obj)
        })
        .catch(err => {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        })
      },
      async deleteObj(obj) {
        let app = this;
        if(obj.status<0) return
        if(obj.status==1){
          app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.type")}),app.$t("common.message"))
          return
        }
        let res=await app.$store.dispatch(`st_type/delete_obj`,obj.id)
        if(res&&res.data.ok){
          app.get_ls_type()
          app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
        }else{
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
        }
      },
      clear() {
        this.type={id:null,name:null,code:moment().valueOf().toString(),
        logo:null,des:null,user_create_id:null,user_update_id:null,company_id:null,branch_id:null,type:0}
        this.$refs.txt_name.$el.focus()
        this.$v.$reset()
      },
      searchLike:function(item){
        return item.name.includes(this.filter)
        ||item.code.includes(this.filter)
        ||item.type.toString().includes(this.filter)
        ||item.des&&item.des.includes(this.filter)
        ||item.branch&&item.branch.name.includes(this.filter)
        ||item.company.name.includes(this.filter)
      },
      getRowCount (items) {
        return items.length
      },
      rowClicked (item) {
        if(item.type==1000) return
        debugger
        this.type=item
      },

      getImage:function(e){
        let app=this
        let image=e.target.files[0]
        if(image.size>102400){
          app.$bvModal.show("viewModal")
          document.getElementById("fileControl").value = ""
          return
        }
        let reader=new FileReader()
        reader.readAsDataURL(image)
        reader.onload=e=>{
          app.type.logo=e.target.result
        }
      }
    }
}
</script>
